import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  listFilters,
  applyFilterWithManager,
  KlevuFetch,
  search,
  sendSearchEvent,
  personalisation,
  KlevuTypeOfRecord,
  advancedFiltering,
  KlevuListenDomEvent,
  KlevuDomEvents,
} from '@klevu/core';
import get from 'lodash/get';
import map from 'lodash/map';
import { getSettings } from '../../utils/klevuUtils';

export function useKlevuContentSearch(term, manager, sorting, pageSize, defaultFilters = []) {
  const [contentQueryResults, setContentQueryResultsResult] = useState();

  const [cmsSearchResponse, setSearchResponse] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [searchInProgress, setSearchInProgress] = useState(true);
  const [currentContentPage, setCurrentContentPage] = useState(1);
  const [settings, setKlevuSettings] = useState();

  const advancedFilters = useMemo(
    () =>
      map(defaultFilters, filter => ({
        excludeValuesInResult: true,
        key: filter.key,
        singleSelect: false,
        valueOperator: 'INCLUDE',
        values: filter.values,
      })),
    [defaultFilters],
  );

  const initialFetch = useCallback(async () => {
    setSearchInProgress(true);
    const modifiers = [
      listFilters({
        filterManager: manager,
        limit: 100,
        filtersToReturn: {
          enabled: true,
        },
      }),
      advancedFiltering(advancedFilters),
      applyFilterWithManager(manager),
      sendSearchEvent(),
    ];

    if (get(settings, ['klevu_uc_userOptions', 'enablePersonalisationInSearch'])) {
      modifiers.push(personalisation());
    }

    const functions = [
      search(
        term,
        {
          id: 'cms',
          limit: pageSize,
          sort: sorting,
          typeOfRecords: [KlevuTypeOfRecord.Cms],
        },
        ...modifiers,
      ),
    ];
    const QueryResults = await KlevuFetch(...functions);

    const cmsSearchResult = QueryResults?.queriesById('cms');

    setContentQueryResultsResult(QueryResults);

    setSearchResponse(cmsSearchResult);

    setFilters(manager.filters);
    setSearchInProgress(false);
  }, [manager, advancedFilters, settings, term, pageSize, sorting]);

  const handleFilterUpdate = () => {
    setFilters(manager.filters);
    initialFetch();
  };

  const fetchKlevuSettings = async () => {
    const ksettings = await getSettings();
    setKlevuSettings(ksettings);
    return ksettings;
  };

  useEffect(() => {
    fetchKlevuSettings();
    const stop = KlevuListenDomEvent(KlevuDomEvents.FilterSelectionUpdate, handleFilterUpdate);

    // cleanup this component
    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, sorting]);

  useEffect(() => {
    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, sorting]);

  const ChangePage = useCallback(
    async pageNumber => {
      setSearchInProgress(true);

      const nextResponse = await cmsSearchResponse.getPage({
        filterManager: manager,
        pageIndex: pageNumber - 1,
      });

      const _nextPageResult = nextResponse.queriesById('cms');

      setContentQueryResultsResult(nextResponse);

      setSearchResponse(_nextPageResult);
      setCurrentContentPage(pageNumber);
      setSearchInProgress(false);
    },
    [cmsSearchResponse, manager],
  );

  return {
    cmsSearchResponse,
    suggestions: contentQueryResults?.suggestionsById('suggestions')?.suggestions.map(i => i.suggest) ?? [],
    resultCount: {
      content: get(cmsSearchResponse, ['meta', 'totalResultsFound'], 0),
    },
    filters,
    searchInProgress,
    currentContentPage,
    settings,
    ChangePage: async pageNumber => {
      await ChangePage(pageNumber);
    },
  };
}
