/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual, isNil, isString } from 'lodash';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { contentfulContentTypes } from '../../../../constants';
import { siteId } from '../../../../sites';

import AssetModule from '../../../module/AssetModule';
import LinkBlockModule from '../../../module/LinkBlockModule';
import ImageModule from '../../../module/ImageModule';
import VideoModule from '../../../module/VideoModule';
import ProductListModule from '../../../module/ProductListModule';
import FeaturedProductModule from '../../../module/FeaturedProductModule';
import HeroImageModule from '../../../module/HeroImageModule';
import ContactPersonModule from '../../../module/ContactPersonModule';
import LatestArticlesModule from '../../../module/LatestArticlesModule';
import AccordionModule from '../../../module/AccordionModule';
import ContentListModule from '../../../module/ContentListModule';
import MediaGalleryModule from '../../../module/MediaGalleryModule';
import LeadTextModule from '../../../module/LeadTextModule';
import ButtonLinkModule from '../../../module/ButtonLinkModule';
import SocialMediaElementModule from '../../../module/SocialMediaElementModule';
import TableModule from '../../../module/TableModule';
import FormModule from '../../../module/FormModule';
import PreparationSectionModule from '../../../module/PreparationSectionModule';
import PreparationStepModule from '../../../module/PreparationStepModule';
import SearchModule from '../../../module/SearchModule';
import AnalyticsModule from '../../../module/AnalyticsModule';
import InspirationalAreaModule from '../../../module/InspirationalAreaModule';
import Link from '../../../navigation/Link';
import IdentifierModule from '../../../module/IdentifierModule';
import AudioModule from '../../../module/AudioModule';
import OrderFormModule from '../../../module/OrderFormModule';
import IframeModule from '../../../module/IframeModule';
import InstagramFeedModule from '../../../module/InstagramFeedModule';
import EventCalendarModule from '../../../module/EventCalendarModule';

const styles = theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
  paragraph: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    marginBottom: '1rem',
    whiteSpace: 'pre-line',
    '&+ h2': {
      marginTop: '3rem',
    },
    '&+ h3': {
      marginTop: '2rem',
    },
  },
  quote: {
    fontSize: theme.palette.quote.fontSize,
    fontWeight: 'inherit',
    textAlign: 'left',
    borderLeft: `4px solid ${theme.palette.quote.text}`,
    padding: theme.spacing(2, 2),
    margin: theme.spacing(4, 0),
  },
  socialMedia: {},
  listItem: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  olList: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'left',
    '&+ h2': {
      marginTop: '3rem',
    },
    '&+ h3': {
      marginTop: '2rem',
    },
  },
  ulList: {
    listStyle: 'square',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textAlign: 'left',
    '&+ h2': {
      marginTop: '3rem',
    },
    '&+ h3': {
      marginTop: '2rem',
    },
  },
  heading1: {
    whiteSpace: 'pre-line',
  },
  heading2: { whiteSpace: 'pre-line' },
  heading3: { whiteSpace: 'pre-line' },
  heading4: { whiteSpace: 'pre-line' },
  heading5: { whiteSpace: 'pre-line' },
  heading6: { whiteSpace: 'pre-line' },
  hyperlink: {
    cursor: 'pointer',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  bold: {
    fontSize: 'inherit',
    fontWeight: theme.typography.fontWeightBold,
  },
  italic: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontStyle: 'italic',
  },
  underline: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'underline',
  },
  hr: {
    margin: '2rem 0',
    ...theme.hr,
  },
  preparationStepModule: {},
  tableHeaderCell: {
    backgroundColor: theme.table.head.backgroundColor,
    color: theme.table.head.color,
  },
  tableCell: {
    fontSize: 'inherit',
  },
});

function makeRichText() {
  return withStyles(styles)(RichText); // eslint-disable-line no-use-before-define
}

function RichText({ classes, className, textData, pageData, ...otherProps }) {
  const options = useMemo(() => {
    return {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          if (isEqual(get(children, 'length'), 1) && isEmpty(get(children, '0'))) {
            return null;
          }
          return (
            <Typography className={classes.paragraph} {...otherProps}>
              {children}
            </Typography>
          );
        },
        [BLOCKS.HEADING_1]: (node, children) => (
          <Typography variant="h1" className={classes.heading1} {...otherProps}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Typography variant="h2" className={classes.heading2} {...otherProps}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Typography variant="h3" className={classes.heading3} {...otherProps}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
          <Typography variant="h4" className={classes.heading4} {...otherProps}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
          <Typography variant="h5" className={classes.heading5} {...otherProps}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_6]: (node, children) => (
          <Typography variant="h6" className={classes.heading6} {...otherProps}>
            {children}
          </Typography>
        ),
        [BLOCKS.HR]: (node, children) => <Divider className={classes.hr} />,
        [BLOCKS.LIST_ITEM]: (node, children) => (
          <Typography component="li" className={classes.listItem}>
            {children}
          </Typography>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
          <Typography component="ol" className={classes.olList}>
            {children}
          </Typography>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
          <Typography component="ul" className={classes.ulList}>
            {children}
          </Typography>
        ),
        [BLOCKS.QUOTE]: (node, children) => (
          <Typography component="blockquote" className={classes.quote}>
            {children}
          </Typography>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
          const entryData = get(node, 'data.target');
          const contentType = get(entryData, 'internal.type');
          const richText = makeRichText();

          switch (contentType) {
            case contentfulContentTypes.AssetModule: {
              return <AssetModule data={entryData} richText={richText} />;
            }
            default: {
              return null;
            }
          }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          const entryData = get(node, 'data.target');
          const contentType = get(entryData, 'internal.type');

          const richText = makeRichText();
          switch (contentType) {
            case contentfulContentTypes.LinkBlockModule: {
              return <LinkBlockModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.ImageModule: {
              return <ImageModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.VideoModule: {
              return <VideoModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.ProductListModule: {
              return <ProductListModule data={entryData} pageData={pageData} richText={richText} />;
            }
            case contentfulContentTypes.FeaturedProductModule: {
              return <FeaturedProductModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.HeroImageModule: {
              return <HeroImageModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.LatestArticlesModule: {
              return <LatestArticlesModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.ContactPersonModule: {
              return <ContactPersonModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.AccordionModule: {
              return <AccordionModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.ContentListModule: {
              return <ContentListModule data={entryData} pageData={pageData} richText={richText} />;
            }
            case contentfulContentTypes.MediaGalleryModule: {
              return <MediaGalleryModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.LeadTextModule: {
              return <LeadTextModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.TableModule: {
              return <TableModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.FormModule: {
              return <FormModule data={entryData} pageData={pageData} richText={richText} />;
            }
            case contentfulContentTypes.SearchModule: {
              return <SearchModule data={entryData} pageData={pageData} richText={richText} />;
            }
            case contentfulContentTypes.PreparationSection: {
              return <PreparationSectionModule data={entryData} pageData={pageData} richText={richText} />;
            }
            case contentfulContentTypes.PreparationStep: {
              return (
                <PreparationStepModule data={entryData} richText={richText} className={classes.preparationStepModule} />
              );
            }
            case contentfulContentTypes.InspirationalAreaModule: {
              return <InspirationalAreaModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.AnalyticsModule: {
              return <AnalyticsModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.IdentifierModule: {
              return <IdentifierModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.AudioModule: {
              return <AudioModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.OrderFormModule: {
              return <OrderFormModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.IFrameModule: {
              return <IframeModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.InstagramFeedModule: {
              return <InstagramFeedModule data={entryData} pageData={pageData} richText={richText} />;
            }
            case contentfulContentTypes.ButtonLinkModule: {
              return <ButtonLinkModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.EventCalendarModule: {
              return <EventCalendarModule data={entryData} pageData={pageData} richText={richText} />;
            }
            default: {
              return null;
            }
          }
        },
        [BLOCKS.TABLE]: (node, children) => (
          <TableContainer>
            <Table>
              <TableBody>{children}</TableBody>
            </Table>
          </TableContainer>
        ),
        [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
        [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
          <TableCell component="th" scope="row" className={classes.tableHeaderCell}>
            {children}
          </TableCell>
        ),
        [BLOCKS.TABLE_CELL]: (node, children) => <TableCell className={classes.tableCell}>{children}</TableCell>,
        [INLINES.HYPERLINK]: (node, children) => {
          const dataUri = get(node, 'data.uri');
          let target =
            dataUri.includes(`//${siteId}.`) || dataUri.startsWith('#') || dataUri.startsWith('/') ? '_self' : '_blank';

          if (otherProps.target === '_blank') {
            target = '_blank';
          }
          return (
            <Link to={dataUri} target={target} className={classes.hyperlink} {...otherProps}>
              {children}
            </Link>
          );
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          const entryData = get(node, 'data.target');
          const fullPath = get(entryData, 'fullPath');

          let target = '_self';
          if (otherProps.target === '_blank') {
            target = '_blank';
          }

          return (
            <Link to={fullPath} target={target} className={classes.hyperlink} {...otherProps}>
              {children}
            </Link>
          );
        },
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          const entryData = get(node, 'data.target');
          const fileUrl = get(entryData, 'file.url');

          return (
            <Link to={fileUrl} target="_blank" className={classes.hyperlink} {...otherProps}>
              {children}
            </Link>
          );
        },
        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
          const entryData = get(node, 'data.target');
          const contentType = get(entryData, 'internal.type');
          const richText = makeRichText();

          switch (contentType) {
            case contentfulContentTypes.ButtonLinkModule: {
              return <ButtonLinkModule data={entryData} richText={richText} />;
            }
            case contentfulContentTypes.SocialMediaElementModule: {
              return (
                <SocialMediaElementModule
                  data={entryData}
                  richText={richText}
                  className={classes.socialMedia}
                  {...otherProps}
                />
              );
            }
            default: {
              return null;
            }
          }
        },
      },
      renderMark: {
        [MARKS.BOLD]: text => (
          <Typography component="span" className={classes.bold}>
            {text}
          </Typography>
        ),
        [MARKS.CODE]: text => (
          <Typography component="span" className={classes.code}>
            {text}
          </Typography>
        ),
        [MARKS.ITALIC]: text => (
          <Typography component="span" className={classes.italic}>
            {text}
          </Typography>
        ),
        [MARKS.UNDERLINE]: text => (
          <Typography component="span" className={classes.underline}>
            {text}
          </Typography>
        ),
      },
    };
  }, [classes, pageData]);

  if (isString(textData)) {
    return (
      <div className={clsx(classes.root, className)}>
        <Typography className={classes.paragraph}>{textData}</Typography>
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      {renderRichText(isNil(textData) ? { raw: null } : textData, options)}
    </div>
  );
}

RichText.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  textData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RichText.defaultProps = {
  classes: {},
  className: null,
  textData: null,
  pageData: null,
};

export default withStyles(styles)(RichText);
