import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';
import { get, map } from 'lodash';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import ProductCard from '../../../shared/Products/ProductCard';
import Slider from '../../../shared/Slider';
import { breakPoints } from '../../../../constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center ',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  minusMargin: {
    [theme.breakpoints.up('1000')]: {
      margin: '0 -100px',
    },
    [theme.breakpoints.up('1240')]: {
      margin: '0 -220px',
    },
  },
  noMinusMargin: {
    margin: '0',
  },
  carousel: { position: 'relative', width: '100%' },
});

function ProductListModule({ classes, className, data }) {
  const inspectorProps = useContentfulInspectorMode();
  const contentListRef = useRef(null);
  const [enableMinusMargin, setEnableMinusMargin] = useState(true);

  const setMargin = () => {
    const moduleWidth = get(contentListRef, 'current.clientWidth');
    if (moduleWidth > breakPoints.lg) {
      setEnableMinusMargin(false);
    }
  };

  useEffect(() => {
    setMargin();
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', setMargin);
      return () => window.removeEventListener('resize', setMargin);
    }
    return () => {};
  }, [contentListRef]);

  let featuredProducts = get(data, 'featuredProducts');
  const listAppearance = get(data, 'listingAppearance') ? get(data, 'listingAppearance') : 'Slider';
  const slider = !!listAppearance.includes('Slider');
  const productListingLogic = get(data, 'productListingLogic');

  if (productListingLogic === 'List products which are on sale') {
    featuredProducts = get(data, 'productsOnSale');
  }

  return (
    <div
      className={clsx(classes.root, className, enableMinusMargin ? classes.minusMargin : classes.noMinusMargin)}
      ref={contentListRef}
      {...inspectorProps({
        entryId: get(data, 'contentful_id'),
        fieldId: 'featuredProducts',
        locale: 'en-US',
      })}>
      {slider && (
        <Slider className={classes.carousel}>
          {map(featuredProducts, (featuredProduct, index) => {
            const relatedProduct = get(featuredProduct, 'relatedProduct', featuredProduct);
            const relatedProductId = get(relatedProduct, 'id');
            const relatedProductKey = `${relatedProductId}-${index}`;
            return <ProductCard key={relatedProductKey} productData={relatedProduct} />;
          })}
        </Slider>
      )}
      {!slider &&
        map(featuredProducts, (featuredProduct, index) => {
          const relatedProduct = get(featuredProduct, 'relatedProduct', featuredProduct);
          const relatedProductId = get(relatedProduct, 'id');
          const relatedProductKey = `${relatedProductId}-${index}`;
          return <ProductCard key={relatedProductKey} productData={relatedProduct} />;
        })}
    </div>
  );
}

ProductListModule.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ProductListModule.defaultProps = {
  classes: {},
  className: null,
  data: null,
  pageData: null,
};

export default withStyles(styles)(ProductListModule);
