import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { filter, get, isEmpty, isEqual, map } from 'lodash';

import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Visible from '../../../shared/Visible';
import Link from '../../Link';

import '../../../page/Content';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  list1: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem1: {
    padding: '0 25px',
    position: 'relative',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover > div': {
      visibility: 'visible',
      transitionDelay: '0s',
    },
  },
  link1: {
    ...theme.typography.navigationLink,
    display: 'flex',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.navigation.hoverText,
    },
  },
  activeLink1: {
    borderBottom: `4px solid ${theme.palette.header.navigation.activeLinkBorder}`,
    color: theme.palette.header.navigation.activeLink,
  },
  subMenu: {
    marginTop: '26px',
    visibility: 'hidden',
    transition: '0.05s 0.05s',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    minWidth: '380px',
    padding: '15px 15px 30px',
    background: theme.palette.header.navigation.subMenuBackground,
  },

  list2: {
    color: theme.palette.header.navigation.subMenuBulletPoints,
    columnCount: '1',
    listStyle: 'square',
    fontSize: '1rem',
  },
  listItem2: {},
  link2: {
    ...theme.typography.navigationLink,
    fontSize: '1rem',
    color: theme.palette.header.text,
    display: 'flex',
    padding: '7.5px 10px',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.header.navigation.hoverText,
    },
  },
  activeLink2: {
    color: theme.palette.header.navigation.activeLink,
  },
});

function getNavigationElementTitle(navigationElement) {
  let navigationElementTitle = get(navigationElement, 'pageReference.menuTitle');
  if (isEmpty(navigationElementTitle)) {
    navigationElementTitle = get(navigationElement, 'pageReference.title');
  }
  if (isEmpty(navigationElementTitle)) {
    navigationElementTitle = get(navigationElement, 'title');
  }
  return navigationElementTitle;
}

function getNavigationElementChildren(navigationElements, navigationElementId) {
  return filter(navigationElements, {
    parentNavigationElement: { id: navigationElementId },
  });
}

function TopNavigation({ classes, className, pageData }) {
  // const navigationElements = get(pageData, 'topNavigation.flattenedNavigationElements');
  const navigationElements = get(pageData, 'mainTheme.navigation.flattenedNavigationElements');
  const navigationElements1 = filter(navigationElements, { parentNavigationElement: null });

  return (
    <div className={clsx(classes.root, className)}>
      <ul className={classes.list1}>
        {map(navigationElements1, navigationElement1 => {
          const navigationElementId1 = get(navigationElement1, 'id');
          const navigationElementFullPath1 = get(navigationElement1, 'pageReference.fullPath');
          const navigationElementChildren1 = getNavigationElementChildren(navigationElements, navigationElementId1);
          const navigationElementTitle1 = getNavigationElementTitle(navigationElement1);
          const isMainPage = isEqual(navigationElementFullPath1, '/');

          return (
            <li key={navigationElementId1} className={classes.listItem1}>
              <Link
                to={navigationElementFullPath1}
                className={classes.link1}
                activeClassName={classes.activeLink1}
                partiallyActive={!isMainPage}>
                {navigationElementTitle1}
              </Link>
              <Visible hidden={isEmpty(navigationElementChildren1)}>
                <div className={classes.subMenu}>
                  <ul className={classes.list2}>
                    {map(navigationElementChildren1, navigationElement2 => {
                      const navigationElementId2 = get(navigationElement2, 'id');
                      const navigationElementFullPath2 = get(navigationElement2, 'pageReference.fullPath');
                      const navigationElementTitle2 = getNavigationElementTitle(navigationElement2);

                      return (
                        <li key={navigationElementId2} className={classes.listItem2}>
                          <Link
                            to={navigationElementFullPath2}
                            className={classes.link2}
                            activeClassName={classes.activeLink2}>
                            {navigationElementTitle2}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Visible>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

TopNavigation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TopNavigation.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export const query = graphql`
  fragment NavigationMenuFragment on ContentfulNavigationMenu {
    id
  }
  fragment TopNavigationFragment on ContentfulNavigation {
    title
    flattenedNavigationElements {
      id
      title
      parentNavigationElement {
        id
      }
      subNavigationElements {
        ...NavigationMenuFragment
      }
      pageReference {
        ...ContentLandingPageFragment
        ...ContentAdvancedPageFragment
        ...ContentArticlePageFragment
        ...ContentRecipePageFragment
        ...ContentBrandFragment
        ...ContentCategoryFragment
      }
    }
  }

  fragment BreadcrumbFragment on PageUnion {
    ...ContentLandingPageFragment
    ...ContentAdvancedPageFragment
    ...ContentArticlePageFragment
    ...ContentRecipePageFragment
    ...ContentBrandFragment
    ...ContentCategoryFragment
    ...ContentProductFragment
  }

  fragment SearchPageFragment on PageUnion {
    ...ContentAdvancedPageFragment
  }

  fragment PrimaryChannelFragment on ContentfulChannel {
    id
    node_locale
    contentful_id
    store
    channel
  }
`;

export default withStyles(styles)(TopNavigation);
