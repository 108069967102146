import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { navigate } from 'gatsby';
import { IconButton, withStyles } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useDialog } from '../../../context/DialogContext';
import { useMemoryList } from '../../../hooks/useMemoryList';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 16px',
    },
  },
  badgeWrapper: {
    position: 'relative',
    display: 'inline-flex',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    backgroundColor: theme.palette.memoryListButton.badge.backgroundColor,
    height: 18,
    minWidth: 18,
    padding: '0 6px',
    borderRadius: 9,
    color: theme.palette.memoryListButton.badge.color,
    fontWeight: 'normal',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: 1,
  },
});

const CustomBadge = ({ classes, content, children }) => (
  <div className={classes.badgeWrapper}>
    {children}
    <div className={classes.badge}>{content}</div>
  </div>
);

CustomBadge.propTypes = {
  classes: PropTypes.shape({
    badgeWrapper: PropTypes.string,
    badge: PropTypes.string,
  }),
  content: PropTypes.number,
  children: PropTypes.node,
};

CustomBadge.defaultProps = {
  classes: {},
  content: 0,
  children: null,
};

function MemoryListIconButton({ classes, className, pageData }) {
  const { t } = useTranslation();
  const memoryListPageURL = get(pageData, 'mainTheme.memoryListPage.fullPath');
  const { openDialog } = useDialog();
  const { prodcutCountInMemoryList } = useMemoryList();

  const handleButtonClick = useCallback(() => {
    if (isEmpty(memoryListPageURL)) {
      openDialog('MemoryListDialog');
    } else {
      navigate(memoryListPageURL);
    }
  }, [memoryListPageURL, openDialog]);

  if (prodcutCountInMemoryList <= 0) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <IconButton
        className={classes.iconButton}
        onClick={handleButtonClick}
        aria-label={t('MemoryList.MemoryListIconButton.label')}>
        <CustomBadge classes={classes} content={prodcutCountInMemoryList}>
          <ListAlt />
        </CustomBadge>
      </IconButton>
    </div>
  );
}

MemoryListIconButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MemoryListIconButton.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default memo(withStyles(styles)(MemoryListIconButton));
